import styled, {css} from 'styled-components';

import {xxl, smMax} from '../../../styles/media';

export const ArrowButtonContainer = styled.div`
    display: flex;
    width: ${(props) => (props.small ? '2.5rem' : '3.5rem')};
    height: ${(props) => (props.small ? '2.5rem' : '3.5rem')};

    ${smMax`
        width: ${(props) => (props.small ? '2.5rem' : '3rem')};
        height: ${(props) => (props.small ? '2.5rem' : '3rem')};
    `}

    position: absolute;
    top: 50%;
    margin-top: -1.75rem;
    z-index: 1;
    ${(props) =>
        props.prev
            ? css`
                  left: -10px;

                  ${xxl`
                      left: 0;
                  `}
              `
            : css`
                  right: -10px;

                  ${xxl`
                      right: 0;
                  `}
              `};

    button {
        width: 100% !important;
        height: 100% !important;
    }
`;
