import React from 'react';
import PropTypes from 'prop-types';

import {StyledImage} from './styles';

const Image = ({src, alt, title, width, height, responsive, onClick}) => {
    const imageProps = {
        src,
        alt,
        title,
        width,
        height,
        responsive,
        onClick,
    };

    return <StyledImage {...imageProps} loading="lazy" />;
};

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    title: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    responsive: PropTypes.bool,
    onClick: PropTypes.func,
};

Image.defaultProps = {
    title: '',
    responsive: false,
};

export default Image;
