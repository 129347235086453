import styled from 'styled-components';
import ShopWindow from '../../../assets/images/trainings/img-schaufenster--hintergrund-desktop.png';

export const ShopWindowWrapper = styled.div`
    border: 0.5rem solid rgba(0, 0, 0, 0.15);
    padding: 4rem 1rem;
    background-image: url(${ShopWindow});
    background-size: cover;
    background-position: center center;

    .ap-teaser {
        max-width: 350px;
        margin: 0 auto;
    }
`;
