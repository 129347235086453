import React from 'react';
import PropTypes from 'prop-types';

import Slider from '../../components/Slider';
import Slide from '../../components/Slider/Slide';
import {ShopWindowWrapper} from './styles';

const ShopWindow = ({teasers, children}) => {
    return (
        <ShopWindowWrapper>
            <Slider>
                {/* don't render random trainings statically in order to avoid hydration issue */}
                {(typeof window === 'undefined' ? [] : teasers).map(
                    (teaser) => (
                        <Slide key={teaser.id}>{children(teaser)}</Slide>
                    )
                )}
            </Slider>
        </ShopWindowWrapper>
    );
};

ShopWindow.propTypes = {
    teasers: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    children: PropTypes.func,
};

ShopWindow.defaultProps = {
    teasers: [],
    children: () => {},
};

export default ShopWindow;
