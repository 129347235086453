import styled from 'styled-components';
import Slider from 'react-slick';

export const StyledSlickSlider = styled(Slider)`
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slick-list {
        position: relative;
        display: block;
        overflow-x: hidden;
        overflow-y: visible;
        padding: 1.875rem 0;
        margin: 0 auto;
        width: 90%;
    }

    .slick-list:focus {
        outline: none;
    }

    .slick-list.dragging {
        cursor: pointer;
    }

    .slick-track {
        padding: 1rem 0;
    }

    &.slick-slider .slick-track,
    &.slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        top: 0;
        left: 0;
        display: flex !important;
    }

    .slick-track:before,
    .slick-track:after {
        display: table;
        content: '';
    }

    .slick-track:after {
        clear: both;
    }

    .slick-loading .slick-track {
        visibility: hidden;
    }

    .slick-slide {
        display: none;
        float: left;
        box-sizing: border-box;
        padding: 0;
        height: inherit !important;
        min-height: 1px;
        margin: ${(props) => (props.imageSlider ? '0 5px' : '0 1.5rem')};
        opacity: 1;

        > div {
            height: 100%;
            outline: none;
        }
    }

    .slick-current {
        opacity: 1;
    }

    [dir='rtl'] .slick-slide {
        float: right;
    }

    .slick-slide.slick-loading img {
        display: none;
    }

    .slick-slide.dragging img {
        pointer-events: none;
    }

    &.slick-initialized .slick-slide {
        display: block;
    }

    .slick-loading .slick-slide {
        visibility: hidden;
    }

    .slick-vertical .slick-slide {
        display: block;

        height: auto;

        border: 1px solid transparent;
    }
`;
