import React from 'react';
import PropTypes from 'prop-types';
import Arrow from './Arrow';

import {StyledSlickSlider} from './styles';
import {slideValidation} from '../../../utils/validator-utils';

const defaultBreakpoints = [
    {
        breakpoint: 1460,
        settings: {
            slidesToShow: 2,
            centerMode: false,
            centerPadding: '0',
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: '0',
        },
    },
];

export const imageSliderBreakpoints = [
    {
        breakpoint: 1460,
        settings: {
            slidesToShow: 4,
            centerMode: false,
            centerPadding: '0',
        },
    },
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 3,
            centerMode: false,
            centerPadding: '0',
        },
    },
    {
        breakpoint: 992,
        settings: {
            slidesToShow: 2,
            centerMode: false,
            centerPadding: '0',
        },
    },
    {
        breakpoint: 576,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            centerMode: false,
            centerPadding: '0',
        },
    },
];

const Slider = ({children, breakpoints, imageSlider}) => {
    const numberOfChildren = React.Children.count(children);
    const settings = {
        dots: false,
        infinite: numberOfChildren !== 1,
        speed: 500,
        slidesToShow: imageSlider
            ? 5
            : numberOfChildren < 3
            ? numberOfChildren
            : 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0 20px',
        nextArrow: <Arrow small={imageSlider} />,
        prevArrow: <Arrow prev small={imageSlider} />,
        responsive: breakpoints,
        swipe: true,
    };
    return (
        <StyledSlickSlider {...settings} imageSlider={imageSlider}>
            {children}
        </StyledSlickSlider>
    );
};

Slider.propTypes = {
    children: slideValidation(),
    breakpoints: PropTypes.array,
    imageSlider: PropTypes.bool,
};

Slider.defaultProps = {
    breakpoints: defaultBreakpoints,
};

export default Slider;
