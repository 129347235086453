import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';
import {ArrowButtonContainer} from './Arrow.styles';

const Arrow = ({onClick, prev, small}) => {
    return (
        <ArrowButtonContainer prev={prev} small={small}>
            {prev ? (
                <Button circle onClick={onClick} icon="arrowBack" />
            ) : (
                <Button circle onClick={onClick} icon="arrowForward" />
            )}
        </ArrowButtonContainer>
    );
};

Arrow.propTypes = {
    onClick: PropTypes.func,
    prev: PropTypes.bool,
    small: PropTypes.bool,
};

export default Arrow;
